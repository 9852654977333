@charset "UTF-8";
* {
  font-family: "文泉驛正黑","WenQuanYi Zen Hei","儷黑 Pro","LiHei Pro","微軟正黑體","Microsoft JhengHei",DFKai-SB,sans-serif;
}

.glyphicon {
  font-family: "Glyphicons Halflings","文泉驛正黑","WenQuanYi Zen Hei","儷黑 Pro","LiHei Pro","微軟正黑體","Microsoft JhengHei",DFKai-SB,sans-serif;
}

:focus {
  outline: none 0px;
}

.bg-image {
  width: 100%;
  height: 100%;
}

.bg-image-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -9;
}

body {
  color: #5d5d5d;
  font-size: 16px;
  background: transparent;
}

.nolink {
  padding: 14px;
  display: block;
}

@media screen and (max-width: 768px) {
  .nolink {
    padding: 9px 15px;
  }
}

#block-hybridauth-hybridauth {
  position: absolute;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  right: 10px;
  top: 0;
  z-index: 999;
  opacity: 0.2;
  zoom: 0.7;
  overflow: hidden;
}

#block-hybridauth-hybridauth h2 {
  display: inline-block;
  vertical-align: sub;
}

#block-hybridauth-hybridauth .content {
  display: inline-block;
  vertical-align: middle;
}

#block-hybridauth-hybridauth:hover {
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  #block-hybridauth-hybridauth {
    zoom: 1;
  }
  #block-hybridauth-hybridauth h2 {
    display: none;
  }
}

.hybridauth-widget-wrapper {
  display: inline-block;
}

.not-logged-in #header-top {
  display: none;
}

div.messages, div.messages.status {
  /* Important messages (status, warning, and error) for the user. See also the declarations in messages.css. */
  margin: 1.5em 0;
  /* Drupal core uses "6px 0" margin */
  padding: 3px 3px 3px 59px;
  color: #3f3f3f;
  border: none;
  background: #75d675 url(../images/icon-tick.png) no-repeat 23px center;
}

div.messages ul, div.messages.status ul {
  margin-top: 0;
  margin-bottom: 0;
}

div.warning, div.messages.warning,
tr.warning {
  /* Medium priority messages */
  border-color: #e9c75f;
}

div.error, div.messages.error,
tr.error {
  /* High priority messages. See also the .error declaration below. */
  border-color: #e56464;
}

.error {
  /* Errors that are separate from div.messages status messages. */
  color: #3f3f3f;
}

div.messages.warning, div.warning {
  background: #e9c75f url(../images/icon-warning.png) no-repeat 23px center;
}

div.messages.error, div.error {
  background: #e56464 url(../images/icon-cross.png) no-repeat 23px center;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  div.messages.status, div.messages {
    background-image: url(../images/icon-tick-2x.png);
    background-size: 16px 16px;
  }
  div.messages.warning, div.warning {
    background-image: url(../images/icon-warning-2x.png);
    background-size: 16px 16px;
  }
  div.messages.error, div.error {
    background-image: url(../images/icon-cross-2x.png);
    background-size: 16px 16px;
  }
}

.messages-inner {
  padding: 15px 20px;
  background: #fff;
}

div.messages.status, div.status,
.ok {
  color: #3f3f3f;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
select:focus,
textarea:focus,
input.form-text:focus,
textarea.form-textarea:focus {
  border-color: #2196F3;
}

#logo {
  display: inline-block;
  vertical-align: middle;
}

#logo img.header--logo {
  height: 100px;
  border-radius: 15px;
}

#logo img.print-only {
  display: none;
}

#site-name {
  display: inline-block;
  vertical-align: middle;
}

#site-name a {
  color: #4b4961;
  background: rgba(255, 255, 255, 0.8);
  line-height: 1.5;
  border-radius: 15px;
  padding: 0 10px;
  margin: 0;
}

#header {
  padding: 10px 0;
  background: transparent;
  text-align: center;
}

#header.sticky {
  padding-bottom: 60px;
}

.auto-refresh, .print-only, .mobile-version-site-name {
  display: none !important;
}

#main-navigation {
  background: transparent;
}

@media screen and (min-width: 768px) {
  #main-navigation.sticky {
    box-shadow: none;
  }
}

#main-navigation .navbar-header > .container {
  background: rgba(171, 224, 255, 0.85);
  border-radius: 10px;
}

#main-navigation .navbar-header .nav li a {
  letter-spacing: 0.5px;
}

#main-navigation .navbar-header .nav li.active-trail a, #main-navigation .navbar-header .nav li.active a {
  background: rgba(241, 193, 30, 0.81);
}

#main-navigation .navbar-header .nav li > a:hover {
  background: rgba(255, 105, 58, 0.66);
}

#main-navigation .navbar-header .nav li.dropdown:hover {
  background: #b38c53;
}

#main-navigation .navbar-header .nav li.dropdown.open > a {
  background: #b38c53;
}

#main-navigation .navbar-header .nav li.dropdown.open .dropdown-menu {
  background: #b38c53;
  border-bottom-color: #795548;
}

#main-navigation .navbar-header .nav li.dropdown.open .dropdown-menu a {
  border-bottom-color: #795548;
}

option[disabled="disabled"] {
  color: #d5d5d5;
}

.hide {
  display: none;
}

.remove-padding {
  padding: 0;
}

a:hover {
  text-decoration: none;
}

body .navbar-header .navbar-toggle {
  background: #ff2f00;
}

.navbar-header .nav li a, .navbar-header .nav li .nolink {
  color: #1f1f1f;
}

#breadcrumb {
  padding: 5px 0;
  background: rgba(240, 245, 247, 0);
}

#breadcrumb-inside > .container {
  background: rgba(240, 245, 247, 0.9);
  border-radius: 5px;
}

#breadcrumb .breadcrumb {
  margin: 5px 0;
}

#block-jquery-countdown-timer-jquery-countdown-timer {
  padding: 0;
}

#block-jquery-countdown-timer-jquery-countdown-timer h2 {
  color: #ff8300;
  text-align: center;
  margin: 0 0 5px;
  font-size: 28px;
}

#block-jquery-countdown-timer-jquery-countdown-timer #jquery-countdown-timer-note, #block-jquery-countdown-timer-jquery-countdown-timer .countWeeks, #block-jquery-countdown-timer-jquery-countdown-timer .countDiv0 {
  display: none;
}

#block-jquery-countdown-timer-jquery-countdown-timer #jquery-countdown-timer {
  width: 100%;
  height: 43px;
  overflow: hidden;
}

#main-content {
  padding: 0 0 20px;
  background: rgba(240, 245, 247, 0);
}

#main-content > .container {
  background: rgba(240, 245, 247, 0.8);
  -moz-box-shadow: 0 5px 20px 1px #abd8f2;
  -webkit-box-shadow: 0 5px 20px 1px #abd8f2;
  box-shadow: 0 5px 20px 1px #abd8f2;
  border-radius: 5px;
}

h1.page-title {
  margin: 10px 0;
}

tbody tr td {
  vertical-align: middle;
}

tr.even {
  background-color: #DADADA !important;
  border-bottom: 2px solid #F5F5F5;
}

tr.even td {
  background-color: #DADADA !important;
  border-bottom: 2px solid #F5F5F5;
}

tr.odd {
  background-color: #F5F5F5;
  border-bottom: 2px solid #CCC;
}

tr.odd td {
  background-color: #F5F5F5;
  border-bottom: 2px solid #CCC;
}

.field-type-taxonomy-term-reference {
  position: inherit;
  float: inherit;
}

.form-item .tgf-group .tgf-group-child {
  margin-left: 0.5em;
  margin-top: 0;
  margin-bottom: 0.2em;
}

#injured-record-node-form input[type="checkbox"] {
  width: 24px;
  height: 24px;
}

/* Score Theme */
.gray-right {
  border-right: #A8A7A7 solid 2px;
}

.gray-bottom-imp {
  border-bottom: #A8A7A7 solid 2px !important;
}

.white-bottom {
  border-bottom: 5px solid #cccccc;
}

.views-row-last .white-bottom-last {
  border-bottom: solid 5px #cccccc;
}

.white-left {
  border-left: 5px solid #cccccc;
}

.white-right {
  border-right: 5px solid #cccccc;
}

.white-top {
  border-top: 5px solid #cccccc;
}

.grays-bottom {
  border-bottom: 5px solid #777777;
}

.views-row-last .grays-bottom-last {
  border-bottom: solid 5px #777777;
}

.grays-left {
  border-left: 5px solid #777777;
}

.grays-right {
  border-right: 5px solid #777777;
}

.grays-top {
  border-top: 5px solid #777777;
}

.purple-top {
  border-top: #7D87F7 solid 5px;
}

.purple-bottom {
  border-bottom: #7D87F7 solid 5px;
}

.views-row-last .purple-bottom-last {
  border-bottom: #7D87F7 solid 5px;
}

.purple-right {
  border-right: #7D87F7 solid 5px;
}

.purple-left {
  border-left: #7D87F7 solid 5px;
}

.blue-bottom {
  border-bottom: 5px solid #10b3e2;
}

.views-row-last .blue-bottom-last {
  border-bottom: solid 5px #10b3e2;
}

.blue-left {
  border-left: 5px solid #10b3e2;
}

.blue-right {
  border-right: 5px solid #10b3e2;
}

.blue-top {
  border-top: 5px solid #10b3e2;
}

.green-top {
  border-top: #45CE92 solid 5px;
}

.green-bottom {
  border-bottom: #45CE92 solid 5px;
}

.views-row-last .green-bottom-last {
  border-bottom: #45CE92 solid 5px;
}

.green-right {
  border-right: #45CE92 solid 5px;
}

.green-left {
  border-left: #45CE92 solid 5px;
}

.orange-top {
  border-top: #F8BA37 solid 5px;
}

.orange-bottom {
  border-bottom: #F8BA37 solid 5px;
}

.views-row-last .orange-bottom-last {
  border-bottom: #F8BA37 solid 5px;
}

.orange-right {
  border-right: #F8BA37 solid 5px;
}

.orange-left {
  border-left: #F8BA37 solid 5px;
}

.pink-top {
  border-top: #FF879F solid 5px;
}

.pink-bottom {
  border-bottom: #FF879F solid 5px;
}

.views-row-last .pink-bottom-last {
  border-bottom: #FF879F solid 5px;
}

.pink-right {
  border-right: #FF879F solid 5px;
}

.pink-left {
  border-left: #FF879F solid 5px;
}

.red-top {
  border-top: #F00 solid 5px;
}

.red-bottom {
  border-bottom: #F00 solid 5px;
}

.views-row-last .red-bottom-last {
  border-bottom: #F00 solid 5px;
}

.red-right {
  border-right: #F00 solid 5px;
}

.red-left {
  border-left: #F00 solid 5px;
}

.path-event .content {
  padding: 0;
}

.path-event .content .field-name-body p {
  font-size: 1.2em;
  letter-spacing: 1px;
  line-height: 1.5;
}

.page-event-result table {
  width: 100%;
}

@media screen and (max-width: 550px) {
  .page-event-result table {
    width: auto;
    position: relative;
    white-space: nowrap;
    display: block;
    overflow-x: auto;
  }
  .page-event-result .views-field-php {
    min-width: 150px;
  }
}

.page-event-result th {
  background-color: #FFF;
  border-bottom: 3px solid #ccc;
}

.page-event-result .view-event-result .view-header h2 {
  font-size: 2em;
  text-align: center;
}

.page-event-result #paying-form {
  padding: 0 20px 20px;
  border: #54B4D2 5px solid;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.4);
}

.page-event-result .pay-status {
  padding: 15px;
  min-width: 180px;
}

.page-event-result .pay-status .text {
  font-size: 1.2em;
  font-weight: 700;
  color: white;
}

.page-event-result .pay-status .text img {
  display: inline;
}

.page-event-result .pay-status p {
  margin: 0 !important;
}

.page-event-result .pay-status.nopay {
  background: #e56464;
}

.page-event-result .pay-status.nopay .text img.paying_edit {
  padding-left: 10px;
  cursor: pointer;
}

.page-event-result .pay-status.nopay .text img.paying_at_check {
  cursor: pointer;
  background-color: #C5EAA3;
  padding: 5px;
  border-radius: 10px;
  width: 30px;
  height: 30px;
}

.page-event-result .pay-status.checking {
  background: #e9c75f;
}

.page-event-result .pay-status.checking img.paying_edit {
  padding-left: 10px;
  cursor: pointer;
}

.page-event-result .pay-status.checking img.paying_check {
  cursor: pointer;
  background-color: #C5EAA3;
  padding: 5px;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  margin-bottom: -2px;
}

.page-event-result .pay-status.checking .payinfo {
  background: rgba(255, 255, 255, 0.8);
}

.page-event-result .pay-status.payed {
  background: #75d675;
}

.page-event-result .pay-status.payed .payinfo {
  background: rgba(255, 255, 255, 0.8);
}

.page-player th {
  background-color: #FFF;
  border-bottom: 3px solid #ccc;
}

.page-player .views-table {
  width: 99%;
  margin: 0 0.385%;
  display: inline-table;
}

@media screen and (min-width: 480px) {
  .page-player .views-table {
    width: 48.5%;
  }
}

@media screen and (min-width: 720px) {
  .page-player .views-table {
    width: 32.2%;
  }
}

@media screen and (min-width: 960px) {
  .page-player .views-table {
    width: 23.5%;
  }
}

.page-player .views-table caption {
  font-size: 1.5em;
  text-align: center;
  color: initial;
}

.page-event-signup table {
  width: 100%;
}

@media screen and (max-width: 740px) {
  .page-event-signup .views-field-php {
    min-width: 150px;
  }
}

.page-event-signup th {
  background-color: #FFF;
  border-bottom: 3px solid #ccc;
}

.page-event-signup th.views-field-field-qua-rfid-editable {
  display: none;
}

.page-event-signup th.views-field-field-final-rfid-editable {
  display: none;
}

.page-event-signup .view-event-signup .view-header h2, .page-event-signup .view-event-final-signup .view-header h2 {
  font-size: 2em;
  text-align: center;
}

.page-event-signup .signup-status {
  padding: 15px;
}

.page-event-signup .signup-status .text {
  position: relative;
  left: -15px;
  font-size: 1.2em;
  font-weight: 700;
  color: white;
}

.page-event-signup .signup-status .text img {
  display: inline;
}

.page-event-signup .signup-status .text input[type="text"] {
  width: auto;
}

.page-event-signup .signup-status p {
  margin: 0px;
}

.page-event-signup .signup-status.unsign {
  background: #e56464;
}

.page-event-signup .signup-status.unsign .text img.signup_edit {
  padding-left: 10px;
  position: absolute;
  top: -3px;
  cursor: pointer;
}

.page-event-signup .signup-status.signed {
  background: #75d675;
}

.path-time tr td:first-child {
  text-align: center;
  border-right: #C7C7C7 solid 5px;
}

.path-time table {
  font-size: 19px;
  border-color: transparent;
}

.path-time tr:nth-child(2n) {
  background-color: #dadada !important;
  border-bottom: 2px solid #f5f5f5;
}

.path-time tr:nth-child(n+1) {
  background-color: #f5f5f5;
  border-bottom: 2px solid #CCC;
}

@media screen and (max-width: 767px) {
  .path-time table {
    width: 100% !important;
  }
}

.page-qua-list #header {
  display: none;
}

.page-qua-list #main-navigation {
  background-image: url(/sites/all/themes/bootpress/images/yh2019_logo.png);
  background-repeat: no-repeat;
  background-size: auto 42px;
  background-position: 10px 5px;
}

.page-qua-list #main-navigation.sticky {
  position: relative;
}

.page-qua-list #secondary-menu {
  display: none;
}

.page-qua-list .breadcrumb, .page-qua-list #header-top {
  display: none;
}

.page-qua-list #page {
  overflow: hidden;
}

.page-qua-list #page-title {
  margin: 0;
}

.page-qua-list #content {
  padding: 0 10px;
}

.page-qua-list input.qua_add_text {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.page-qua-list .view-display-id-qua_list table, .page-qua-list .view-display-id-qua_list_2 table {
  width: 100%;
  margin-top: 0px;
  font-size: 18px;
  font-weight: 700;
}

.page-qua-list .view-display-id-qua_list table thead, .page-qua-list .view-display-id-qua_list_2 table thead {
  font-size: 10px;
}

.page-qua-list .view-display-id-qua_list table thead th, .page-qua-list .view-display-id-qua_list_2 table thead th {
  padding: 5px;
}

.page-qua-list .view-display-id-qua_list table tbody .views-field-title, .page-qua-list .view-display-id-qua_list_2 table tbody .views-field-title {
  padding: 0 5px;
  max-width: 100px;
}

.page-qua-list .view-display-id-qua_list > .view-content, .page-qua-list .view-display-id-qua_list > .view-footer {
  width: 49%;
  display: inline-block;
  vertical-align: top;
}

.page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(1), .page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(2), .page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(3) {
  font-size: 20px;
  border: #F00 solid 5px;
}

.page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(1) td, .page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(2) td, .page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(3) td {
  padding: 0px;
  height: 55px;
}

.page-qua-edit {
  font-size: 14px;
}

.page-qua-edit #header {
  display: none;
}

.page-qua-edit #main-navigation {
  background-image: url(/sites/all/themes/bootpress/images/yh2019_logo.png);
  background-repeat: no-repeat;
  background-size: auto 42px;
  background-position: 10px 5px;
}

.page-qua-edit #main-navigation.sticky {
  position: relative;
}

.page-qua-edit #secondary-menu {
  display: none;
}

.page-qua-edit .breadcrumb, .page-qua-edit #header-top {
  display: none;
}

.page-qua-edit #page-title {
  margin: 0;
  padding-top: 5px;
}

.page-qua-edit #content {
  padding: 0 20px 120px;
}

.page-qua-edit .return, .page-qua-edit .delete {
  padding-left: 10px;
  display: inline-block;
}

.page-qua-edit table {
  width: 100%;
  max-width: 100%;
  text-align: center;
  font-size: 1.5em;
  font-weight: 500;
  margin: 0px;
}

.page-qua-edit table tbody tr.header td {
  padding: 10px;
  width: 50%;
}

.page-qua-edit table tbody tr.status td {
  margin: 5px 5%;
  padding: 20px 10px;
}

.page-qua-edit table tbody tr.status td .btn {
  padding: 50px 0;
  font-size: 30px;
}

.page-qua-edit table tbody tr.status td .btn:hover, .page-qua-edit table tbody tr.status td .btn:active, .page-qua-edit table tbody tr.status td .btn:focus {
  background-position: 0;
  border-color: #BBB;
}

.page-qua-edit table tbody tr.status td .btn[done="0"] {
  background-color: #FFF;
  background-image: linear-gradient(to bottom, #BBB, #BBB);
  color: #777;
  border-width: 5px;
}

.page-qua-edit table tbody tr.status td .btn[done="0"]:before {
  content: "-";
  color: #444;
  font-size: 30px;
  line-height: 0.5;
  position: absolute;
  top: 20%;
  width: 100%;
  text-align: center;
  left: 0;
}

.page-qua-edit table tbody tr.status td .btn[done="1"] {
  border-width: 5px;
}

.page-qua-edit table tbody tr.status td .btn[done="1"]:before {
  content: "✔";
  color: #9dffa2;
  font-size: 30px;
  line-height: 0.5;
  position: absolute;
  top: 20%;
  width: 100%;
  text-align: center;
  left: 0;
}

.page-qua-edit .view-display-id-qua_edit_next_2 {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 2000;
}

.page-qua-edit .view-display-id-qua_edit_next_2 .views-row {
  display: inline-block;
  width: 49%;
  text-align: center;
  padding: 5px;
  background: #ffac33;
  margin: auto;
  color: #FFF;
  position: relative;
}

.page-qua-edit .view-display-id-qua_edit_next_2 .views-row .views-field-counter {
  text-align: left;
  font-size: 2.5em;
  font-weight: 700;
  padding: 0 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.page-qua-edit .view-display-id-qua_edit_next_2 .views-row .views-field-php {
  text-align: left;
  font-size: 1.2em;
  padding-left: 25px;
  vertical-align: top;
}

.page-qua-edit .view-display-id-qua_edit_next_2 .views-row .views-field-title {
  display: inline-block;
  width: 80%;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 20px;
  max-height: 40px;
  min-height: 30px;
  vertical-align: bottom;
}

.page-qua-full .view-display-id-qua_list_full_1 > .view-header > .view {
  display: inline-block;
  width: 32%;
  margin: 0 0.5%;
}

.page-qua-full .view-display-id-qua_list_full_1 > .view-header > .view table {
  width: 100%;
}

.page-qua-full .views-field-title {
  max-width: 125px;
}

@media screen and (max-width: 1024px) {
  .page-qua-full .view-display-id-qua_list_full_1 > .view-header > .view {
    display: block;
    width: 100%;
    margin: 0 1%;
  }
}

.page-score #breadcrumb {
  display: none;
}

.path-score-qua-edit, .path-score-final-edit,
.path-score-qua-rank, .path-score-final-rank,
.path-score-final-none, .path-score-final-elementary, .path-score-final-v1-v2, .path-score-final-v3-v4, .path-score-final-v5,
.path-score-final-none-start, .path-score-final-elementary-start, .path-score-final-v1-v2-start, .path-score-final-v3-v4-start, .path-score-final-v5-start,
.path-score-qua-none, .path-score-qua-elementary, .path-score-qua-v1-v2, .path-score-qua-v3-v4, .path-score-qua-v5,
.path-score-all-none, .path-score-all-elementary, .path-score-all-v1-v2, .path-score-all-v3-v4, .path-score-all-v5 {
  font-size: 1.2em;
}

.path-score-qua-edit .nav-tabs, .path-score-final-edit .nav-tabs,
.path-score-qua-rank .nav-tabs, .path-score-final-rank .nav-tabs,
.path-score-final-none .nav-tabs, .path-score-final-elementary .nav-tabs, .path-score-final-v1-v2 .nav-tabs, .path-score-final-v3-v4 .nav-tabs, .path-score-final-v5 .nav-tabs,
.path-score-final-none-start .nav-tabs, .path-score-final-elementary-start .nav-tabs, .path-score-final-v1-v2-start .nav-tabs, .path-score-final-v3-v4-start .nav-tabs, .path-score-final-v5-start .nav-tabs,
.path-score-qua-none .nav-tabs, .path-score-qua-elementary .nav-tabs, .path-score-qua-v1-v2 .nav-tabs, .path-score-qua-v3-v4 .nav-tabs, .path-score-qua-v5 .nav-tabs,
.path-score-all-none .nav-tabs, .path-score-all-elementary .nav-tabs, .path-score-all-v1-v2 .nav-tabs, .path-score-all-v3-v4 .nav-tabs, .path-score-all-v5 .nav-tabs {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 auto;
  padding: 0;
  max-width: 1200px;
  list-style: none;
  -ms-box-orient: horizontal;
  -ms-box-pack: center;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  border-bottom: none 0px;
}

@media screen and (max-width: 992px) {
  .path-score-qua-edit .nav-tabs, .path-score-final-edit .nav-tabs,
  .path-score-qua-rank .nav-tabs, .path-score-final-rank .nav-tabs,
  .path-score-final-none .nav-tabs, .path-score-final-elementary .nav-tabs, .path-score-final-v1-v2 .nav-tabs, .path-score-final-v3-v4 .nav-tabs, .path-score-final-v5 .nav-tabs,
  .path-score-final-none-start .nav-tabs, .path-score-final-elementary-start .nav-tabs, .path-score-final-v1-v2-start .nav-tabs, .path-score-final-v3-v4-start .nav-tabs, .path-score-final-v5-start .nav-tabs,
  .path-score-qua-none .nav-tabs, .path-score-qua-elementary .nav-tabs, .path-score-qua-v1-v2 .nav-tabs, .path-score-qua-v3-v4 .nav-tabs, .path-score-qua-v5 .nav-tabs,
  .path-score-all-none .nav-tabs, .path-score-all-elementary .nav-tabs, .path-score-all-v1-v2 .nav-tabs, .path-score-all-v3-v4 .nav-tabs, .path-score-all-v5 .nav-tabs {
    -webkit-justify-content: inherit;
    -moz-justify-content: inherit;
    -ms-justify-content: inherit;
    justify-content: inherit;
  }
}

.path-score-qua-edit .nav-tabs li, .path-score-final-edit .nav-tabs li,
.path-score-qua-rank .nav-tabs li, .path-score-final-rank .nav-tabs li,
.path-score-final-none .nav-tabs li, .path-score-final-elementary .nav-tabs li, .path-score-final-v1-v2 .nav-tabs li, .path-score-final-v3-v4 .nav-tabs li, .path-score-final-v5 .nav-tabs li,
.path-score-final-none-start .nav-tabs li, .path-score-final-elementary-start .nav-tabs li, .path-score-final-v1-v2-start .nav-tabs li, .path-score-final-v3-v4-start .nav-tabs li, .path-score-final-v5-start .nav-tabs li,
.path-score-qua-none .nav-tabs li, .path-score-qua-elementary .nav-tabs li, .path-score-qua-v1-v2 .nav-tabs li, .path-score-qua-v3-v4 .nav-tabs li, .path-score-qua-v5 .nav-tabs li,
.path-score-all-none .nav-tabs li, .path-score-all-elementary .nav-tabs li, .path-score-all-v1-v2 .nav-tabs li, .path-score-all-v3-v4 .nav-tabs li, .path-score-all-v5 .nav-tabs li {
  position: relative;
  z-index: 1;
  display: block;
  margin: 0;
  text-align: center;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media screen and (max-width: 992px) {
  .path-score-qua-edit .nav-tabs li, .path-score-final-edit .nav-tabs li,
  .path-score-qua-rank .nav-tabs li, .path-score-final-rank .nav-tabs li,
  .path-score-final-none .nav-tabs li, .path-score-final-elementary .nav-tabs li, .path-score-final-v1-v2 .nav-tabs li, .path-score-final-v3-v4 .nav-tabs li, .path-score-final-v5 .nav-tabs li,
  .path-score-final-none-start .nav-tabs li, .path-score-final-elementary-start .nav-tabs li, .path-score-final-v1-v2-start .nav-tabs li, .path-score-final-v3-v4-start .nav-tabs li, .path-score-final-v5-start .nav-tabs li,
  .path-score-qua-none .nav-tabs li, .path-score-qua-elementary .nav-tabs li, .path-score-qua-v1-v2 .nav-tabs li, .path-score-qua-v3-v4 .nav-tabs li, .path-score-qua-v5 .nav-tabs li,
  .path-score-all-none .nav-tabs li, .path-score-all-elementary .nav-tabs li, .path-score-all-v1-v2 .nav-tabs li, .path-score-all-v3-v4 .nav-tabs li, .path-score-all-v5 .nav-tabs li {
    -webkit-flex: inherit;
    -moz-flex: inherit;
    -ms-flex: inherit;
    flex: inherit;
    zoom: 0.75;
  }
}

.path-score-qua-edit .nav-tabs li a, .path-score-final-edit .nav-tabs li a,
.path-score-qua-rank .nav-tabs li a, .path-score-final-rank .nav-tabs li a,
.path-score-final-none .nav-tabs li a, .path-score-final-elementary .nav-tabs li a, .path-score-final-v1-v2 .nav-tabs li a, .path-score-final-v3-v4 .nav-tabs li a, .path-score-final-v5 .nav-tabs li a,
.path-score-final-none-start .nav-tabs li a, .path-score-final-elementary-start .nav-tabs li a, .path-score-final-v1-v2-start .nav-tabs li a, .path-score-final-v3-v4-start .nav-tabs li a, .path-score-final-v5-start .nav-tabs li a,
.path-score-qua-none .nav-tabs li a, .path-score-qua-elementary .nav-tabs li a, .path-score-qua-v1-v2 .nav-tabs li a, .path-score-qua-v3-v4 .nav-tabs li a, .path-score-qua-v5 .nav-tabs li a,
.path-score-all-none .nav-tabs li a, .path-score-all-elementary .nav-tabs li a, .path-score-all-v1-v2 .nav-tabs li a, .path-score-all-v3-v4 .nav-tabs li a, .path-score-all-v5 .nav-tabs li a {
  padding: 0 1.5em 0 0.3em;
  color: #ff5c5c;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  line-height: 2.5;
  font-weight: 700;
  letter-spacing: 1px;
}

.path-score-qua-edit .nav-tabs li a:after, .path-score-final-edit .nav-tabs li a:after,
.path-score-qua-rank .nav-tabs li a:after, .path-score-final-rank .nav-tabs li a:after,
.path-score-final-none .nav-tabs li a:after, .path-score-final-elementary .nav-tabs li a:after, .path-score-final-v1-v2 .nav-tabs li a:after, .path-score-final-v3-v4 .nav-tabs li a:after, .path-score-final-v5 .nav-tabs li a:after,
.path-score-final-none-start .nav-tabs li a:after, .path-score-final-elementary-start .nav-tabs li a:after, .path-score-final-v1-v2-start .nav-tabs li a:after, .path-score-final-v3-v4-start .nav-tabs li a:after, .path-score-final-v5-start .nav-tabs li a:after,
.path-score-qua-none .nav-tabs li a:after, .path-score-qua-elementary .nav-tabs li a:after, .path-score-qua-v1-v2 .nav-tabs li a:after, .path-score-qua-v3-v4 .nav-tabs li a:after, .path-score-qua-v5 .nav-tabs li a:after,
.path-score-all-none .nav-tabs li a:after, .path-score-all-elementary .nav-tabs li a:after, .path-score-all-v1-v2 .nav-tabs li a:after, .path-score-all-v3-v4 .nav-tabs li a:after, .path-score-all-v5 .nav-tabs li a:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  outline: 1px solid transparent;
  border-radius: 10px 10px 0 0;
  background: #f7b7b6;
  box-shadow: inset 0 -3px 3px rgba(0, 0, 0, 0.05);
  content: '';
  -webkit-transform: perspective(5px) rotateX(0.93deg) translateZ(-0.8px);
  transform: perspective(5px) rotateX(0.93deg) translateZ(-0.8px);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (max-width: 992px) {
  .path-score-qua-edit .nav-tabs li a:after, .path-score-final-edit .nav-tabs li a:after,
  .path-score-qua-rank .nav-tabs li a:after, .path-score-final-rank .nav-tabs li a:after,
  .path-score-final-none .nav-tabs li a:after, .path-score-final-elementary .nav-tabs li a:after, .path-score-final-v1-v2 .nav-tabs li a:after, .path-score-final-v3-v4 .nav-tabs li a:after, .path-score-final-v5 .nav-tabs li a:after,
  .path-score-final-none-start .nav-tabs li a:after, .path-score-final-elementary-start .nav-tabs li a:after, .path-score-final-v1-v2-start .nav-tabs li a:after, .path-score-final-v3-v4-start .nav-tabs li a:after, .path-score-final-v5-start .nav-tabs li a:after,
  .path-score-qua-none .nav-tabs li a:after, .path-score-qua-elementary .nav-tabs li a:after, .path-score-qua-v1-v2 .nav-tabs li a:after, .path-score-qua-v3-v4 .nav-tabs li a:after, .path-score-qua-v5 .nav-tabs li a:after,
  .path-score-all-none .nav-tabs li a:after, .path-score-all-elementary .nav-tabs li a:after, .path-score-all-v1-v2 .nav-tabs li a:after, .path-score-all-v3-v4 .nav-tabs li a:after, .path-score-all-v5 .nav-tabs li a:after {
    padding-left: 0.6rem;
  }
}

.path-score-qua-edit .nav-tabs li a:hover, .path-score-final-edit .nav-tabs li a:hover,
.path-score-qua-rank .nav-tabs li a:hover, .path-score-final-rank .nav-tabs li a:hover,
.path-score-final-none .nav-tabs li a:hover, .path-score-final-elementary .nav-tabs li a:hover, .path-score-final-v1-v2 .nav-tabs li a:hover, .path-score-final-v3-v4 .nav-tabs li a:hover, .path-score-final-v5 .nav-tabs li a:hover,
.path-score-final-none-start .nav-tabs li a:hover, .path-score-final-elementary-start .nav-tabs li a:hover, .path-score-final-v1-v2-start .nav-tabs li a:hover, .path-score-final-v3-v4-start .nav-tabs li a:hover, .path-score-final-v5-start .nav-tabs li a:hover,
.path-score-qua-none .nav-tabs li a:hover, .path-score-qua-elementary .nav-tabs li a:hover, .path-score-qua-v1-v2 .nav-tabs li a:hover, .path-score-qua-v3-v4 .nav-tabs li a:hover, .path-score-qua-v5 .nav-tabs li a:hover,
.path-score-all-none .nav-tabs li a:hover, .path-score-all-elementary .nav-tabs li a:hover, .path-score-all-v1-v2 .nav-tabs li a:hover, .path-score-all-v3-v4 .nav-tabs li a:hover, .path-score-all-v5 .nav-tabs li a:hover {
  background: inherit;
  border: transparent;
  color: #FFF;
  opacity: 0.75;
}

.path-score-qua-edit .nav-tabs li a.active, .path-score-final-edit .nav-tabs li a.active,
.path-score-qua-rank .nav-tabs li a.active, .path-score-final-rank .nav-tabs li a.active,
.path-score-final-none .nav-tabs li a.active, .path-score-final-elementary .nav-tabs li a.active, .path-score-final-v1-v2 .nav-tabs li a.active, .path-score-final-v3-v4 .nav-tabs li a.active, .path-score-final-v5 .nav-tabs li a.active,
.path-score-final-none-start .nav-tabs li a.active, .path-score-final-elementary-start .nav-tabs li a.active, .path-score-final-v1-v2-start .nav-tabs li a.active, .path-score-final-v3-v4-start .nav-tabs li a.active, .path-score-final-v5-start .nav-tabs li a.active,
.path-score-qua-none .nav-tabs li a.active, .path-score-qua-elementary .nav-tabs li a.active, .path-score-qua-v1-v2 .nav-tabs li a.active, .path-score-qua-v3-v4 .nav-tabs li a.active, .path-score-qua-v5 .nav-tabs li a.active,
.path-score-all-none .nav-tabs li a.active, .path-score-all-elementary .nav-tabs li a.active, .path-score-all-v1-v2 .nav-tabs li a.active, .path-score-all-v3-v4 .nav-tabs li a.active, .path-score-all-v5 .nav-tabs li a.active {
  color: #ff7a7c;
}

.path-score-qua-edit .nav-tabs li a.active:after, .path-score-final-edit .nav-tabs li a.active:after,
.path-score-qua-rank .nav-tabs li a.active:after, .path-score-final-rank .nav-tabs li a.active:after,
.path-score-final-none .nav-tabs li a.active:after, .path-score-final-elementary .nav-tabs li a.active:after, .path-score-final-v1-v2 .nav-tabs li a.active:after, .path-score-final-v3-v4 .nav-tabs li a.active:after, .path-score-final-v5 .nav-tabs li a.active:after,
.path-score-final-none-start .nav-tabs li a.active:after, .path-score-final-elementary-start .nav-tabs li a.active:after, .path-score-final-v1-v2-start .nav-tabs li a.active:after, .path-score-final-v3-v4-start .nav-tabs li a.active:after, .path-score-final-v5-start .nav-tabs li a.active:after,
.path-score-qua-none .nav-tabs li a.active:after, .path-score-qua-elementary .nav-tabs li a.active:after, .path-score-qua-v1-v2 .nav-tabs li a.active:after, .path-score-qua-v3-v4 .nav-tabs li a.active:after, .path-score-qua-v5 .nav-tabs li a.active:after,
.path-score-all-none .nav-tabs li a.active:after, .path-score-all-elementary .nav-tabs li a.active:after, .path-score-all-v1-v2 .nav-tabs li a.active:after, .path-score-all-v3-v4 .nav-tabs li a.active:after, .path-score-all-v5 .nav-tabs li a.active:after {
  background: #fff;
  box-shadow: none;
}

.path-score-qua-edit table, .path-score-final-edit table,
.path-score-qua-rank table, .path-score-final-rank table,
.path-score-final-none table, .path-score-final-elementary table, .path-score-final-v1-v2 table, .path-score-final-v3-v4 table, .path-score-final-v5 table,
.path-score-final-none-start table, .path-score-final-elementary-start table, .path-score-final-v1-v2-start table, .path-score-final-v3-v4-start table, .path-score-final-v5-start table,
.path-score-qua-none table, .path-score-qua-elementary table, .path-score-qua-v1-v2 table, .path-score-qua-v3-v4 table, .path-score-qua-v5 table,
.path-score-all-none table, .path-score-all-elementary table, .path-score-all-v1-v2 table, .path-score-all-v3-v4 table, .path-score-all-v5 table {
  width: 100%;
  text-align: center;
  border: none;
}

.path-score-qua-edit thead, .path-score-final-edit thead,
.path-score-qua-rank thead, .path-score-final-rank thead,
.path-score-final-none thead, .path-score-final-elementary thead, .path-score-final-v1-v2 thead, .path-score-final-v3-v4 thead, .path-score-final-v5 thead,
.path-score-final-none-start thead, .path-score-final-elementary-start thead, .path-score-final-v1-v2-start thead, .path-score-final-v3-v4-start thead, .path-score-final-v5-start thead,
.path-score-qua-none thead, .path-score-qua-elementary thead, .path-score-qua-v1-v2 thead, .path-score-qua-v3-v4 thead, .path-score-qua-v5 thead,
.path-score-all-none thead, .path-score-all-elementary thead, .path-score-all-v1-v2 thead, .path-score-all-v3-v4 thead, .path-score-all-v5 thead {
  border-bottom: 5px solid #CCC;
}

.path-score-qua-edit thead th, .path-score-final-edit thead th,
.path-score-qua-rank thead th, .path-score-final-rank thead th,
.path-score-final-none thead th, .path-score-final-elementary thead th, .path-score-final-v1-v2 thead th, .path-score-final-v3-v4 thead th, .path-score-final-v5 thead th,
.path-score-final-none-start thead th, .path-score-final-elementary-start thead th, .path-score-final-v1-v2-start thead th, .path-score-final-v3-v4-start thead th, .path-score-final-v5-start thead th,
.path-score-qua-none thead th, .path-score-qua-elementary thead th, .path-score-qua-v1-v2 thead th, .path-score-qua-v3-v4 thead th, .path-score-qua-v5 thead th,
.path-score-all-none thead th, .path-score-all-elementary thead th, .path-score-all-v1-v2 thead th, .path-score-all-v3-v4 thead th, .path-score-all-v5 thead th {
  border-bottom: 0;
  background: #FFF;
  vertical-align: middle;
}

.path-score-qua-edit thead th.empty, .path-score-final-edit thead th.empty,
.path-score-qua-rank thead th.empty, .path-score-final-rank thead th.empty,
.path-score-final-none thead th.empty, .path-score-final-elementary thead th.empty, .path-score-final-v1-v2 thead th.empty, .path-score-final-v3-v4 thead th.empty, .path-score-final-v5 thead th.empty,
.path-score-final-none-start thead th.empty, .path-score-final-elementary-start thead th.empty, .path-score-final-v1-v2-start thead th.empty, .path-score-final-v3-v4-start thead th.empty, .path-score-final-v5-start thead th.empty,
.path-score-qua-none thead th.empty, .path-score-qua-elementary thead th.empty, .path-score-qua-v1-v2 thead th.empty, .path-score-qua-v3-v4 thead th.empty, .path-score-qua-v5 thead th.empty,
.path-score-all-none thead th.empty, .path-score-all-elementary thead th.empty, .path-score-all-v1-v2 thead th.empty, .path-score-all-v3-v4 thead th.empty, .path-score-all-v5 thead th.empty {
  background: transparent;
  border: none;
}

.path-score-qua-edit input[type="text"], .path-score-final-edit input[type="text"],
.path-score-qua-rank input[type="text"], .path-score-final-rank input[type="text"],
.path-score-final-none input[type="text"], .path-score-final-elementary input[type="text"], .path-score-final-v1-v2 input[type="text"], .path-score-final-v3-v4 input[type="text"], .path-score-final-v5 input[type="text"],
.path-score-final-none-start input[type="text"], .path-score-final-elementary-start input[type="text"], .path-score-final-v1-v2-start input[type="text"], .path-score-final-v3-v4-start input[type="text"], .path-score-final-v5-start input[type="text"],
.path-score-qua-none input[type="text"], .path-score-qua-elementary input[type="text"], .path-score-qua-v1-v2 input[type="text"], .path-score-qua-v3-v4 input[type="text"], .path-score-qua-v5 input[type="text"],
.path-score-all-none input[type="text"], .path-score-all-elementary input[type="text"], .path-score-all-v1-v2 input[type="text"], .path-score-all-v3-v4 input[type="text"], .path-score-all-v5 input[type="text"] {
  width: 35px;
  text-align: center;
  padding: 0;
  min-height: inherit;
}

.path-score-qua-edit label.option, .path-score-final-edit label.option,
.path-score-qua-rank label.option, .path-score-final-rank label.option,
.path-score-final-none label.option, .path-score-final-elementary label.option, .path-score-final-v1-v2 label.option, .path-score-final-v3-v4 label.option, .path-score-final-v5 label.option,
.path-score-final-none-start label.option, .path-score-final-elementary-start label.option, .path-score-final-v1-v2-start label.option, .path-score-final-v3-v4-start label.option, .path-score-final-v5-start label.option,
.path-score-qua-none label.option, .path-score-qua-elementary label.option, .path-score-qua-v1-v2 label.option, .path-score-qua-v3-v4 label.option, .path-score-qua-v5 label.option,
.path-score-all-none label.option, .path-score-all-elementary label.option, .path-score-all-v1-v2 label.option, .path-score-all-v3-v4 label.option, .path-score-all-v5 label.option {
  display: none;
}

.path-score-qua-edit input[type="checkbox"], .path-score-final-edit input[type="checkbox"],
.path-score-qua-rank input[type="checkbox"], .path-score-final-rank input[type="checkbox"],
.path-score-final-none input[type="checkbox"], .path-score-final-elementary input[type="checkbox"], .path-score-final-v1-v2 input[type="checkbox"], .path-score-final-v3-v4 input[type="checkbox"], .path-score-final-v5 input[type="checkbox"],
.path-score-final-none-start input[type="checkbox"], .path-score-final-elementary-start input[type="checkbox"], .path-score-final-v1-v2-start input[type="checkbox"], .path-score-final-v3-v4-start input[type="checkbox"], .path-score-final-v5-start input[type="checkbox"],
.path-score-qua-none input[type="checkbox"], .path-score-qua-elementary input[type="checkbox"], .path-score-qua-v1-v2 input[type="checkbox"], .path-score-qua-v3-v4 input[type="checkbox"], .path-score-qua-v5 input[type="checkbox"],
.path-score-all-none input[type="checkbox"], .path-score-all-elementary input[type="checkbox"], .path-score-all-v1-v2 input[type="checkbox"], .path-score-all-v3-v4 input[type="checkbox"], .path-score-all-v5 input[type="checkbox"] {
  width: 30px;
  height: 30px;
  min-height: inherit;
}

.path-score-qua-edit th, .path-score-qua-edit td, .path-score-final-edit th, .path-score-final-edit td,
.path-score-qua-rank th,
.path-score-qua-rank td, .path-score-final-rank th, .path-score-final-rank td,
.path-score-final-none th,
.path-score-final-none td, .path-score-final-elementary th, .path-score-final-elementary td, .path-score-final-v1-v2 th, .path-score-final-v1-v2 td, .path-score-final-v3-v4 th, .path-score-final-v3-v4 td, .path-score-final-v5 th, .path-score-final-v5 td,
.path-score-final-none-start th,
.path-score-final-none-start td, .path-score-final-elementary-start th, .path-score-final-elementary-start td, .path-score-final-v1-v2-start th, .path-score-final-v1-v2-start td, .path-score-final-v3-v4-start th, .path-score-final-v3-v4-start td, .path-score-final-v5-start th, .path-score-final-v5-start td,
.path-score-qua-none th,
.path-score-qua-none td, .path-score-qua-elementary th, .path-score-qua-elementary td, .path-score-qua-v1-v2 th, .path-score-qua-v1-v2 td, .path-score-qua-v3-v4 th, .path-score-qua-v3-v4 td, .path-score-qua-v5 th, .path-score-qua-v5 td,
.path-score-all-none th,
.path-score-all-none td, .path-score-all-elementary th, .path-score-all-elementary td, .path-score-all-v1-v2 th, .path-score-all-v1-v2 td, .path-score-all-v3-v4 th, .path-score-all-v3-v4 td, .path-score-all-v5 th, .path-score-all-v5 td {
  padding: 5px;
  text-align: center !important;
}

.path-score-qua-edit .views-row-edit-static, .path-score-final-edit .views-row-edit-static,
.path-score-qua-rank .views-row-edit-static, .path-score-final-rank .views-row-edit-static,
.path-score-final-none .views-row-edit-static, .path-score-final-elementary .views-row-edit-static, .path-score-final-v1-v2 .views-row-edit-static, .path-score-final-v3-v4 .views-row-edit-static, .path-score-final-v5 .views-row-edit-static,
.path-score-final-none-start .views-row-edit-static, .path-score-final-elementary-start .views-row-edit-static, .path-score-final-v1-v2-start .views-row-edit-static, .path-score-final-v3-v4-start .views-row-edit-static, .path-score-final-v5-start .views-row-edit-static,
.path-score-qua-none .views-row-edit-static, .path-score-qua-elementary .views-row-edit-static, .path-score-qua-v1-v2 .views-row-edit-static, .path-score-qua-v3-v4 .views-row-edit-static, .path-score-qua-v5 .views-row-edit-static,
.path-score-all-none .views-row-edit-static, .path-score-all-elementary .views-row-edit-static, .path-score-all-v1-v2 .views-row-edit-static, .path-score-all-v3-v4 .views-row-edit-static, .path-score-all-v5 .views-row-edit-static {
  display: none;
}

.path-score-qua-edit .views-row-edit-edit label, .path-score-final-edit .views-row-edit-edit label,
.path-score-qua-rank .views-row-edit-edit label, .path-score-final-rank .views-row-edit-edit label,
.path-score-final-none .views-row-edit-edit label, .path-score-final-elementary .views-row-edit-edit label, .path-score-final-v1-v2 .views-row-edit-edit label, .path-score-final-v3-v4 .views-row-edit-edit label, .path-score-final-v5 .views-row-edit-edit label,
.path-score-final-none-start .views-row-edit-edit label, .path-score-final-elementary-start .views-row-edit-edit label, .path-score-final-v1-v2-start .views-row-edit-edit label, .path-score-final-v3-v4-start .views-row-edit-edit label, .path-score-final-v5-start .views-row-edit-edit label,
.path-score-qua-none .views-row-edit-edit label, .path-score-qua-elementary .views-row-edit-edit label, .path-score-qua-v1-v2 .views-row-edit-edit label, .path-score-qua-v3-v4 .views-row-edit-edit label, .path-score-qua-v5 .views-row-edit-edit label,
.path-score-all-none .views-row-edit-edit label, .path-score-all-elementary .views-row-edit-edit label, .path-score-all-v1-v2 .views-row-edit-edit label, .path-score-all-v3-v4 .views-row-edit-edit label, .path-score-all-v5 .views-row-edit-edit label {
  display: none;
}

.path-score-qua-edit .views-row-edit-edit select, .path-score-final-edit .views-row-edit-edit select,
.path-score-qua-rank .views-row-edit-edit select, .path-score-final-rank .views-row-edit-edit select,
.path-score-final-none .views-row-edit-edit select, .path-score-final-elementary .views-row-edit-edit select, .path-score-final-v1-v2 .views-row-edit-edit select, .path-score-final-v3-v4 .views-row-edit-edit select, .path-score-final-v5 .views-row-edit-edit select,
.path-score-final-none-start .views-row-edit-edit select, .path-score-final-elementary-start .views-row-edit-edit select, .path-score-final-v1-v2-start .views-row-edit-edit select, .path-score-final-v3-v4-start .views-row-edit-edit select, .path-score-final-v5-start .views-row-edit-edit select,
.path-score-qua-none .views-row-edit-edit select, .path-score-qua-elementary .views-row-edit-edit select, .path-score-qua-v1-v2 .views-row-edit-edit select, .path-score-qua-v3-v4 .views-row-edit-edit select, .path-score-qua-v5 .views-row-edit-edit select,
.path-score-all-none .views-row-edit-edit select, .path-score-all-elementary .views-row-edit-edit select, .path-score-all-v1-v2 .views-row-edit-edit select, .path-score-all-v3-v4 .views-row-edit-edit select, .path-score-all-v5 .views-row-edit-edit select {
  width: 72px;
  padding: 10px 0;
}

.path-score-qua-edit .result, .path-score-final-edit .result,
.path-score-qua-rank .result, .path-score-final-rank .result,
.path-score-final-none .result, .path-score-final-elementary .result, .path-score-final-v1-v2 .result, .path-score-final-v3-v4 .result, .path-score-final-v5 .result,
.path-score-final-none-start .result, .path-score-final-elementary-start .result, .path-score-final-v1-v2-start .result, .path-score-final-v3-v4-start .result, .path-score-final-v5-start .result,
.path-score-qua-none .result, .path-score-qua-elementary .result, .path-score-qua-v1-v2 .result, .path-score-qua-v3-v4 .result, .path-score-qua-v5 .result,
.path-score-all-none .result, .path-score-all-elementary .result, .path-score-all-v1-v2 .result, .path-score-all-v3-v4 .result, .path-score-all-v5 .result {
  font-size: 1.3em;
  font-weight: 700;
}

.path-score-qua-edit .rank, .path-score-final-edit .rank,
.path-score-qua-rank .rank, .path-score-final-rank .rank,
.path-score-final-none .rank, .path-score-final-elementary .rank, .path-score-final-v1-v2 .rank, .path-score-final-v3-v4 .rank, .path-score-final-v5 .rank,
.path-score-final-none-start .rank, .path-score-final-elementary-start .rank, .path-score-final-v1-v2-start .rank, .path-score-final-v3-v4-start .rank, .path-score-final-v5-start .rank,
.path-score-qua-none .rank, .path-score-qua-elementary .rank, .path-score-qua-v1-v2 .rank, .path-score-qua-v3-v4 .rank, .path-score-qua-v5 .rank,
.path-score-all-none .rank, .path-score-all-elementary .rank, .path-score-all-v1-v2 .rank, .path-score-all-v3-v4 .rank, .path-score-all-v5 .rank {
  font-weight: 700;
}

.path-score-qua-edit .no input[type="text"], .path-score-final-edit .no input[type="text"],
.path-score-qua-rank .no input[type="text"], .path-score-final-rank .no input[type="text"],
.path-score-final-none .no input[type="text"], .path-score-final-elementary .no input[type="text"], .path-score-final-v1-v2 .no input[type="text"], .path-score-final-v3-v4 .no input[type="text"], .path-score-final-v5 .no input[type="text"],
.path-score-final-none-start .no input[type="text"], .path-score-final-elementary-start .no input[type="text"], .path-score-final-v1-v2-start .no input[type="text"], .path-score-final-v3-v4-start .no input[type="text"], .path-score-final-v5-start .no input[type="text"],
.path-score-qua-none .no input[type="text"], .path-score-qua-elementary .no input[type="text"], .path-score-qua-v1-v2 .no input[type="text"], .path-score-qua-v3-v4 .no input[type="text"], .path-score-qua-v5 .no input[type="text"],
.path-score-all-none .no input[type="text"], .path-score-all-elementary .no input[type="text"], .path-score-all-v1-v2 .no input[type="text"], .path-score-all-v3-v4 .no input[type="text"], .path-score-all-v5 .no input[type="text"] {
  width: 60px;
}

.path-score-qua-edit .rfid input[type="text"], .path-score-final-edit .rfid input[type="text"],
.path-score-qua-rank .rfid input[type="text"], .path-score-final-rank .rfid input[type="text"],
.path-score-final-none .rfid input[type="text"], .path-score-final-elementary .rfid input[type="text"], .path-score-final-v1-v2 .rfid input[type="text"], .path-score-final-v3-v4 .rfid input[type="text"], .path-score-final-v5 .rfid input[type="text"],
.path-score-final-none-start .rfid input[type="text"], .path-score-final-elementary-start .rfid input[type="text"], .path-score-final-v1-v2-start .rfid input[type="text"], .path-score-final-v3-v4-start .rfid input[type="text"], .path-score-final-v5-start .rfid input[type="text"],
.path-score-qua-none .rfid input[type="text"], .path-score-qua-elementary .rfid input[type="text"], .path-score-qua-v1-v2 .rfid input[type="text"], .path-score-qua-v3-v4 .rfid input[type="text"], .path-score-qua-v5 .rfid input[type="text"],
.path-score-all-none .rfid input[type="text"], .path-score-all-elementary .rfid input[type="text"], .path-score-all-v1-v2 .rfid input[type="text"], .path-score-all-v3-v4 .rfid input[type="text"], .path-score-all-v5 .rfid input[type="text"] {
  width: 120px;
}

.path-score-qua-edit .qua-score, .path-score-qua-edit .final-score, .path-score-final-edit .qua-score, .path-score-final-edit .final-score,
.path-score-qua-rank .qua-score,
.path-score-qua-rank .final-score, .path-score-final-rank .qua-score, .path-score-final-rank .final-score,
.path-score-final-none .qua-score,
.path-score-final-none .final-score, .path-score-final-elementary .qua-score, .path-score-final-elementary .final-score, .path-score-final-v1-v2 .qua-score, .path-score-final-v1-v2 .final-score, .path-score-final-v3-v4 .qua-score, .path-score-final-v3-v4 .final-score, .path-score-final-v5 .qua-score, .path-score-final-v5 .final-score,
.path-score-final-none-start .qua-score,
.path-score-final-none-start .final-score, .path-score-final-elementary-start .qua-score, .path-score-final-elementary-start .final-score, .path-score-final-v1-v2-start .qua-score, .path-score-final-v1-v2-start .final-score, .path-score-final-v3-v4-start .qua-score, .path-score-final-v3-v4-start .final-score, .path-score-final-v5-start .qua-score, .path-score-final-v5-start .final-score,
.path-score-qua-none .qua-score,
.path-score-qua-none .final-score, .path-score-qua-elementary .qua-score, .path-score-qua-elementary .final-score, .path-score-qua-v1-v2 .qua-score, .path-score-qua-v1-v2 .final-score, .path-score-qua-v3-v4 .qua-score, .path-score-qua-v3-v4 .final-score, .path-score-qua-v5 .qua-score, .path-score-qua-v5 .final-score,
.path-score-all-none .qua-score,
.path-score-all-none .final-score, .path-score-all-elementary .qua-score, .path-score-all-elementary .final-score, .path-score-all-v1-v2 .qua-score, .path-score-all-v1-v2 .final-score, .path-score-all-v3-v4 .qua-score, .path-score-all-v3-v4 .final-score, .path-score-all-v5 .qua-score, .path-score-all-v5 .final-score {
  font-size: 20px;
  padding: 5px 0;
  font-weight: 700;
}

.path-score-qua-edit .qua-y-, .path-score-qua-edit .qua-y-N, .path-score-final-edit .qua-y-, .path-score-final-edit .qua-y-N,
.path-score-qua-rank .qua-y-,
.path-score-qua-rank .qua-y-N, .path-score-final-rank .qua-y-, .path-score-final-rank .qua-y-N,
.path-score-final-none .qua-y-,
.path-score-final-none .qua-y-N, .path-score-final-elementary .qua-y-, .path-score-final-elementary .qua-y-N, .path-score-final-v1-v2 .qua-y-, .path-score-final-v1-v2 .qua-y-N, .path-score-final-v3-v4 .qua-y-, .path-score-final-v3-v4 .qua-y-N, .path-score-final-v5 .qua-y-, .path-score-final-v5 .qua-y-N,
.path-score-final-none-start .qua-y-,
.path-score-final-none-start .qua-y-N, .path-score-final-elementary-start .qua-y-, .path-score-final-elementary-start .qua-y-N, .path-score-final-v1-v2-start .qua-y-, .path-score-final-v1-v2-start .qua-y-N, .path-score-final-v3-v4-start .qua-y-, .path-score-final-v3-v4-start .qua-y-N, .path-score-final-v5-start .qua-y-, .path-score-final-v5-start .qua-y-N,
.path-score-qua-none .qua-y-,
.path-score-qua-none .qua-y-N, .path-score-qua-elementary .qua-y-, .path-score-qua-elementary .qua-y-N, .path-score-qua-v1-v2 .qua-y-, .path-score-qua-v1-v2 .qua-y-N, .path-score-qua-v3-v4 .qua-y-, .path-score-qua-v3-v4 .qua-y-N, .path-score-qua-v5 .qua-y-, .path-score-qua-v5 .qua-y-N,
.path-score-all-none .qua-y-,
.path-score-all-none .qua-y-N, .path-score-all-elementary .qua-y-, .path-score-all-elementary .qua-y-N, .path-score-all-v1-v2 .qua-y-, .path-score-all-v1-v2 .qua-y-N, .path-score-all-v3-v4 .qua-y-, .path-score-all-v3-v4 .qua-y-N, .path-score-all-v5 .qua-y-, .path-score-all-v5 .qua-y-N {
  font-size: 0;
  color: transparent;
}

.path-score-qua-edit .qua-rank, .path-score-qua-edit .rank, .path-score-qua-edit .qua-y-Y, .path-score-qua-edit td.final-no, .path-score-final-edit .qua-rank, .path-score-final-edit .rank, .path-score-final-edit .qua-y-Y, .path-score-final-edit td.final-no,
.path-score-qua-rank .qua-rank,
.path-score-qua-rank .rank,
.path-score-qua-rank .qua-y-Y,
.path-score-qua-rank td.final-no, .path-score-final-rank .qua-rank, .path-score-final-rank .rank, .path-score-final-rank .qua-y-Y, .path-score-final-rank td.final-no,
.path-score-final-none .qua-rank,
.path-score-final-none .rank,
.path-score-final-none .qua-y-Y,
.path-score-final-none td.final-no, .path-score-final-elementary .qua-rank, .path-score-final-elementary .rank, .path-score-final-elementary .qua-y-Y, .path-score-final-elementary td.final-no, .path-score-final-v1-v2 .qua-rank, .path-score-final-v1-v2 .rank, .path-score-final-v1-v2 .qua-y-Y, .path-score-final-v1-v2 td.final-no, .path-score-final-v3-v4 .qua-rank, .path-score-final-v3-v4 .rank, .path-score-final-v3-v4 .qua-y-Y, .path-score-final-v3-v4 td.final-no, .path-score-final-v5 .qua-rank, .path-score-final-v5 .rank, .path-score-final-v5 .qua-y-Y, .path-score-final-v5 td.final-no,
.path-score-final-none-start .qua-rank,
.path-score-final-none-start .rank,
.path-score-final-none-start .qua-y-Y,
.path-score-final-none-start td.final-no, .path-score-final-elementary-start .qua-rank, .path-score-final-elementary-start .rank, .path-score-final-elementary-start .qua-y-Y, .path-score-final-elementary-start td.final-no, .path-score-final-v1-v2-start .qua-rank, .path-score-final-v1-v2-start .rank, .path-score-final-v1-v2-start .qua-y-Y, .path-score-final-v1-v2-start td.final-no, .path-score-final-v3-v4-start .qua-rank, .path-score-final-v3-v4-start .rank, .path-score-final-v3-v4-start .qua-y-Y, .path-score-final-v3-v4-start td.final-no, .path-score-final-v5-start .qua-rank, .path-score-final-v5-start .rank, .path-score-final-v5-start .qua-y-Y, .path-score-final-v5-start td.final-no,
.path-score-qua-none .qua-rank,
.path-score-qua-none .rank,
.path-score-qua-none .qua-y-Y,
.path-score-qua-none td.final-no, .path-score-qua-elementary .qua-rank, .path-score-qua-elementary .rank, .path-score-qua-elementary .qua-y-Y, .path-score-qua-elementary td.final-no, .path-score-qua-v1-v2 .qua-rank, .path-score-qua-v1-v2 .rank, .path-score-qua-v1-v2 .qua-y-Y, .path-score-qua-v1-v2 td.final-no, .path-score-qua-v3-v4 .qua-rank, .path-score-qua-v3-v4 .rank, .path-score-qua-v3-v4 .qua-y-Y, .path-score-qua-v3-v4 td.final-no, .path-score-qua-v5 .qua-rank, .path-score-qua-v5 .rank, .path-score-qua-v5 .qua-y-Y, .path-score-qua-v5 td.final-no,
.path-score-all-none .qua-rank,
.path-score-all-none .rank,
.path-score-all-none .qua-y-Y,
.path-score-all-none td.final-no, .path-score-all-elementary .qua-rank, .path-score-all-elementary .rank, .path-score-all-elementary .qua-y-Y, .path-score-all-elementary td.final-no, .path-score-all-v1-v2 .qua-rank, .path-score-all-v1-v2 .rank, .path-score-all-v1-v2 .qua-y-Y, .path-score-all-v1-v2 td.final-no, .path-score-all-v3-v4 .qua-rank, .path-score-all-v3-v4 .rank, .path-score-all-v3-v4 .qua-y-Y, .path-score-all-v3-v4 td.final-no, .path-score-all-v5 .qua-rank, .path-score-all-v5 .rank, .path-score-all-v5 .qua-y-Y, .path-score-all-v5 td.final-no {
  font-size: 24px;
  font-weight: 700;
  color: inherit;
}

.path-score-qua-edit .qua-rank input[type="text"], .path-score-qua-edit .rank input[type="text"], .path-score-qua-edit .qua-y-Y input[type="text"], .path-score-qua-edit td.final-no input[type="text"], .path-score-final-edit .qua-rank input[type="text"], .path-score-final-edit .rank input[type="text"], .path-score-final-edit .qua-y-Y input[type="text"], .path-score-final-edit td.final-no input[type="text"],
.path-score-qua-rank .qua-rank input[type="text"],
.path-score-qua-rank .rank input[type="text"],
.path-score-qua-rank .qua-y-Y input[type="text"],
.path-score-qua-rank td.final-no input[type="text"], .path-score-final-rank .qua-rank input[type="text"], .path-score-final-rank .rank input[type="text"], .path-score-final-rank .qua-y-Y input[type="text"], .path-score-final-rank td.final-no input[type="text"],
.path-score-final-none .qua-rank input[type="text"],
.path-score-final-none .rank input[type="text"],
.path-score-final-none .qua-y-Y input[type="text"],
.path-score-final-none td.final-no input[type="text"], .path-score-final-elementary .qua-rank input[type="text"], .path-score-final-elementary .rank input[type="text"], .path-score-final-elementary .qua-y-Y input[type="text"], .path-score-final-elementary td.final-no input[type="text"], .path-score-final-v1-v2 .qua-rank input[type="text"], .path-score-final-v1-v2 .rank input[type="text"], .path-score-final-v1-v2 .qua-y-Y input[type="text"], .path-score-final-v1-v2 td.final-no input[type="text"], .path-score-final-v3-v4 .qua-rank input[type="text"], .path-score-final-v3-v4 .rank input[type="text"], .path-score-final-v3-v4 .qua-y-Y input[type="text"], .path-score-final-v3-v4 td.final-no input[type="text"], .path-score-final-v5 .qua-rank input[type="text"], .path-score-final-v5 .rank input[type="text"], .path-score-final-v5 .qua-y-Y input[type="text"], .path-score-final-v5 td.final-no input[type="text"],
.path-score-final-none-start .qua-rank input[type="text"],
.path-score-final-none-start .rank input[type="text"],
.path-score-final-none-start .qua-y-Y input[type="text"],
.path-score-final-none-start td.final-no input[type="text"], .path-score-final-elementary-start .qua-rank input[type="text"], .path-score-final-elementary-start .rank input[type="text"], .path-score-final-elementary-start .qua-y-Y input[type="text"], .path-score-final-elementary-start td.final-no input[type="text"], .path-score-final-v1-v2-start .qua-rank input[type="text"], .path-score-final-v1-v2-start .rank input[type="text"], .path-score-final-v1-v2-start .qua-y-Y input[type="text"], .path-score-final-v1-v2-start td.final-no input[type="text"], .path-score-final-v3-v4-start .qua-rank input[type="text"], .path-score-final-v3-v4-start .rank input[type="text"], .path-score-final-v3-v4-start .qua-y-Y input[type="text"], .path-score-final-v3-v4-start td.final-no input[type="text"], .path-score-final-v5-start .qua-rank input[type="text"], .path-score-final-v5-start .rank input[type="text"], .path-score-final-v5-start .qua-y-Y input[type="text"], .path-score-final-v5-start td.final-no input[type="text"],
.path-score-qua-none .qua-rank input[type="text"],
.path-score-qua-none .rank input[type="text"],
.path-score-qua-none .qua-y-Y input[type="text"],
.path-score-qua-none td.final-no input[type="text"], .path-score-qua-elementary .qua-rank input[type="text"], .path-score-qua-elementary .rank input[type="text"], .path-score-qua-elementary .qua-y-Y input[type="text"], .path-score-qua-elementary td.final-no input[type="text"], .path-score-qua-v1-v2 .qua-rank input[type="text"], .path-score-qua-v1-v2 .rank input[type="text"], .path-score-qua-v1-v2 .qua-y-Y input[type="text"], .path-score-qua-v1-v2 td.final-no input[type="text"], .path-score-qua-v3-v4 .qua-rank input[type="text"], .path-score-qua-v3-v4 .rank input[type="text"], .path-score-qua-v3-v4 .qua-y-Y input[type="text"], .path-score-qua-v3-v4 td.final-no input[type="text"], .path-score-qua-v5 .qua-rank input[type="text"], .path-score-qua-v5 .rank input[type="text"], .path-score-qua-v5 .qua-y-Y input[type="text"], .path-score-qua-v5 td.final-no input[type="text"],
.path-score-all-none .qua-rank input[type="text"],
.path-score-all-none .rank input[type="text"],
.path-score-all-none .qua-y-Y input[type="text"],
.path-score-all-none td.final-no input[type="text"], .path-score-all-elementary .qua-rank input[type="text"], .path-score-all-elementary .rank input[type="text"], .path-score-all-elementary .qua-y-Y input[type="text"], .path-score-all-elementary td.final-no input[type="text"], .path-score-all-v1-v2 .qua-rank input[type="text"], .path-score-all-v1-v2 .rank input[type="text"], .path-score-all-v1-v2 .qua-y-Y input[type="text"], .path-score-all-v1-v2 td.final-no input[type="text"], .path-score-all-v3-v4 .qua-rank input[type="text"], .path-score-all-v3-v4 .rank input[type="text"], .path-score-all-v3-v4 .qua-y-Y input[type="text"], .path-score-all-v3-v4 td.final-no input[type="text"], .path-score-all-v5 .qua-rank input[type="text"], .path-score-all-v5 .rank input[type="text"], .path-score-all-v5 .qua-y-Y input[type="text"], .path-score-all-v5 td.final-no input[type="text"] {
  width: 50px;
}

.path-score-qua-edit .qua-check-1:before, .path-score-final-edit .qua-check-1:before,
.path-score-qua-rank .qua-check-1:before, .path-score-final-rank .qua-check-1:before,
.path-score-final-none .qua-check-1:before, .path-score-final-elementary .qua-check-1:before, .path-score-final-v1-v2 .qua-check-1:before, .path-score-final-v3-v4 .qua-check-1:before, .path-score-final-v5 .qua-check-1:before,
.path-score-final-none-start .qua-check-1:before, .path-score-final-elementary-start .qua-check-1:before, .path-score-final-v1-v2-start .qua-check-1:before, .path-score-final-v3-v4-start .qua-check-1:before, .path-score-final-v5-start .qua-check-1:before,
.path-score-qua-none .qua-check-1:before, .path-score-qua-elementary .qua-check-1:before, .path-score-qua-v1-v2 .qua-check-1:before, .path-score-qua-v3-v4 .qua-check-1:before, .path-score-qua-v5 .qua-check-1:before,
.path-score-all-none .qua-check-1:before, .path-score-all-elementary .qua-check-1:before, .path-score-all-v1-v2 .qua-check-1:before, .path-score-all-v3-v4 .qua-check-1:before, .path-score-all-v5 .qua-check-1:before {
  content: "✔";
  color: #3c763d;
  padding-right: 3px;
}

.path-score-qua-edit .status-none, .path-score-final-edit .status-none,
.path-score-qua-rank .status-none, .path-score-final-rank .status-none,
.path-score-final-none .status-none, .path-score-final-elementary .status-none, .path-score-final-v1-v2 .status-none, .path-score-final-v3-v4 .status-none, .path-score-final-v5 .status-none,
.path-score-final-none-start .status-none, .path-score-final-elementary-start .status-none, .path-score-final-v1-v2-start .status-none, .path-score-final-v3-v4-start .status-none, .path-score-final-v5-start .status-none,
.path-score-qua-none .status-none, .path-score-qua-elementary .status-none, .path-score-qua-v1-v2 .status-none, .path-score-qua-v3-v4 .status-none, .path-score-qua-v5 .status-none,
.path-score-all-none .status-none, .path-score-all-elementary .status-none, .path-score-all-v1-v2 .status-none, .path-score-all-v3-v4 .status-none, .path-score-all-v5 .status-none {
  color: transparent;
  font-size: 0;
}

.path-score-qua-edit .status-top, .path-score-final-edit .status-top,
.path-score-qua-rank .status-top, .path-score-final-rank .status-top,
.path-score-final-none .status-top, .path-score-final-elementary .status-top, .path-score-final-v1-v2 .status-top, .path-score-final-v3-v4 .status-top, .path-score-final-v5 .status-top,
.path-score-final-none-start .status-top, .path-score-final-elementary-start .status-top, .path-score-final-v1-v2-start .status-top, .path-score-final-v3-v4-start .status-top, .path-score-final-v5-start .status-top,
.path-score-qua-none .status-top, .path-score-qua-elementary .status-top, .path-score-qua-v1-v2 .status-top, .path-score-qua-v3-v4 .status-top, .path-score-qua-v5 .status-top,
.path-score-all-none .status-top, .path-score-all-elementary .status-top, .path-score-all-v1-v2 .status-top, .path-score-all-v3-v4 .status-top, .path-score-all-v5 .status-top {
  font-weight: 700;
  background: #ffbf60 !important;
}

.path-score-qua-edit .result-score, .path-score-final-edit .result-score,
.path-score-qua-rank .result-score, .path-score-final-rank .result-score,
.path-score-final-none .result-score, .path-score-final-elementary .result-score, .path-score-final-v1-v2 .result-score, .path-score-final-v3-v4 .result-score, .path-score-final-v5 .result-score,
.path-score-final-none-start .result-score, .path-score-final-elementary-start .result-score, .path-score-final-v1-v2-start .result-score, .path-score-final-v3-v4-start .result-score, .path-score-final-v5-start .result-score,
.path-score-qua-none .result-score, .path-score-qua-elementary .result-score, .path-score-qua-v1-v2 .result-score, .path-score-qua-v3-v4 .result-score, .path-score-qua-v5 .result-score,
.path-score-all-none .result-score, .path-score-all-elementary .result-score, .path-score-all-v1-v2 .result-score, .path-score-all-v3-v4 .result-score, .path-score-all-v5 .result-score {
  position: absolute;
  top: -4px;
  left: 1px;
}

@media screen and (max-width: 992px) {
  .path-score-qua-edit table, .path-score-final-edit table,
  .path-score-qua-rank table, .path-score-final-rank table,
  .path-score-final-none table, .path-score-final-elementary table, .path-score-final-v1-v2 table, .path-score-final-v3-v4 table, .path-score-final-v5 table,
  .path-score-final-none-start table, .path-score-final-elementary-start table, .path-score-final-v1-v2-start table, .path-score-final-v3-v4-start table, .path-score-final-v5-start table,
  .path-score-qua-none table, .path-score-qua-elementary table, .path-score-qua-v1-v2 table, .path-score-qua-v3-v4 table, .path-score-qua-v5 table,
  .path-score-all-none table, .path-score-all-elementary table, .path-score-all-v1-v2 table, .path-score-all-v3-v4 table, .path-score-all-v5 table {
    position: relative;
    white-space: nowrap;
    display: table;
    overflow-x: auto;
    zoom: 0.8;
  }
}

@media screen and (max-width: 568px) {
  .path-score-qua-edit table, .path-score-final-edit table,
  .path-score-qua-rank table, .path-score-final-rank table,
  .path-score-final-none table, .path-score-final-elementary table, .path-score-final-v1-v2 table, .path-score-final-v3-v4 table, .path-score-final-v5 table,
  .path-score-final-none-start table, .path-score-final-elementary-start table, .path-score-final-v1-v2-start table, .path-score-final-v3-v4-start table, .path-score-final-v5-start table,
  .path-score-qua-none table, .path-score-qua-elementary table, .path-score-qua-v1-v2 table, .path-score-qua-v3-v4 table, .path-score-qua-v5 table,
  .path-score-all-none table, .path-score-all-elementary table, .path-score-all-v1-v2 table, .path-score-all-v3-v4 table, .path-score-all-v5 table {
    width: auto;
    display: block;
  }
}

.path-score-final-edit table {
  zoom: 0.8;
}

.path-score-final-edit input[type="text"] {
  width: 30px;
}

.view-display-id-final_route_1 #breadcrumb, .view-display-id-final_route_2 #breadcrumb, .view-display-id-final_route_3 #breadcrumb {
  display: none;
}

.view-display-id-final_route_1 table, .view-display-id-final_route_2 table, .view-display-id-final_route_3 table {
  width: 100%;
  text-align: center;
}

.view-display-id-final_route_1 input[type="text"], .view-display-id-final_route_2 input[type="text"], .view-display-id-final_route_3 input[type="text"] {
  width: 80%;
  text-align: center;
  min-height: inherit;
  padding: 0;
}

.view-display-id-final_route_1 input[type="submit"], .view-display-id-final_route_2 input[type="submit"], .view-display-id-final_route_3 input[type="submit"] {
  display: none;
}

.view-display-id-final_route_1 input[disabled="disabled"], .view-display-id-final_route_2 input[disabled="disabled"], .view-display-id-final_route_3 input[disabled="disabled"] {
  background: #808080 !important;
}

.view-display-id-final_route_1 input[type="text"][disabled="disabled"], .view-display-id-final_route_2 input[type="text"][disabled="disabled"], .view-display-id-final_route_3 input[type="text"][disabled="disabled"] {
  background: #d6d6d6 !important;
}

.view-display-id-final_route_1 .views-row-edit-static, .view-display-id-final_route_2 .views-row-edit-static, .view-display-id-final_route_3 .views-row-edit-static {
  display: none;
}

.view-display-id-final_route_1 th, .view-display-id-final_route_2 th, .view-display-id-final_route_3 th {
  text-align: center !important;
  padding: 10px;
  background: #FFFFFF;
}

.view-display-id-final_route_1 td, .view-display-id-final_route_2 td, .view-display-id-final_route_3 td {
  padding: 10px 0;
  font-size: 1.5em;
}

.view-display-id-final_route_1 td.no, .view-display-id-final_route_2 td.no, .view-display-id-final_route_3 td.no {
  font-weight: 700;
}

.view-display-id-final_route_1 .row3 input[type="text"], .view-display-id-final_route_2 .row3 input[type="text"], .view-display-id-final_route_3 .row3 input[type="text"] {
  width: 15%;
}

.view-display-id-final_route_1 .row3 input[type="button"], .view-display-id-final_route_2 .row3 input[type="button"], .view-display-id-final_route_3 .row3 input[type="button"] {
  padding-right: 3%;
  padding-left: 3%;
  font-size: 1em;
}

.view-display-id-final_route_1 .row3 input[type="button"].plus, .view-display-id-final_route_2 .row3 input[type="button"].plus, .view-display-id-final_route_3 .row3 input[type="button"].plus {
  padding-left: 6%;
  padding-right: 6%;
}

@media screen and (max-width: 480px) {
  .view-display-id-final_route_1 .row3 input.top_button, .view-display-id-final_route_1 .row3 input.bonus_button, .view-display-id-final_route_2 .row3 input.top_button, .view-display-id-final_route_2 .row3 input.bonus_button, .view-display-id-final_route_3 .row3 input.top_button, .view-display-id-final_route_3 .row3 input.bonus_button {
    font-size: 0.8em;
  }
}

.path-score-final-none-start .remove-start, .path-score-final-elementary-start .remove-start, .path-score-final-v1-v2-start .remove-start, .path-score-final-v3-v4-start .remove-start, .path-score-final-v5-start .remove-start,
.start-list .remove-start {
  display: none;
}

.path-score-final-none-start .add-start, .path-score-final-elementary-start .add-start, .path-score-final-v1-v2-start .add-start, .path-score-final-v3-v4-start .add-start, .path-score-final-v5-start .add-start,
.start-list .add-start {
  display: table-cell !important;
}

.path-score-final-none-start table, .path-score-final-elementary-start table, .path-score-final-v1-v2-start table, .path-score-final-v3-v4-start table, .path-score-final-v5-start table,
.start-list table {
  zoom: 2;
  width: 80% !important;
  margin: auto;
}

.path-score-final-none-start .temp_text, .path-score-final-elementary-start .temp_text, .path-score-final-v1-v2-start .temp_text, .path-score-final-v3-v4-start .temp_text, .path-score-final-v5-start .temp_text,
.start-list .temp_text {
  display: none;
}

.path-score-final-none-start .page-title, .path-score-final-elementary-start .page-title, .path-score-final-v1-v2-start .page-title, .path-score-final-v3-v4-start .page-title, .path-score-final-v5-start .page-title,
.start-list .page-title {
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .page-title, #messages-section {
    zoom: 0.8;
  }
  .view-event-result.view-id-event_result.view-display-id-page {
    zoom: 0.8;
  }
  #logo img {
    margin: -10px 0;
  }
  #logo img.header--logo {
    width: auto;
    height: 50px;
  }
  #site-name a {
    font-size: 28px;
  }
  #main-navigation {
    background: rgba(171, 224, 255, 0.85);
  }
  #main-navigation.sticky {
    background-image: url(/sites/all/themes/bootpress/images/yh2019_logo.png);
    background-repeat: no-repeat;
    background-size: auto 42px;
    background-position: 10px 5px;
  }
  #main-navigation.sticky .mobile-version-site-name {
    display: inline-block !important;
    margin: 6px 0 0 45px;
    padding: 5px;
    border-radius: 11px;
    background-color: rgba(255, 255, 255, 0.6);
  }
  #main-navigation.sticky .mobile-version-site-name > a {
    color: #4b4961;
    font-size: 20px;
    font-weight: 500;
  }
  #main-navigation .navbar-header > .container {
    background: inherit;
  }
  #main-navigation .navbar-header .nav li {
    background: #b38c53;
  }
  #main-navigation .navbar-header .nav li a, #main-navigation .navbar-header .nav li .nolink {
    border-bottom: 1px solid #795548;
    letter-spacing: 0.5px;
  }
  #main-navigation .navbar-header .nav li.active-trail a, #main-navigation .navbar-header .nav li.active a {
    background: #753c28;
  }
  #main-navigation .navbar-header .nav li:hover {
    background: #3e2f29;
  }
  .path-event .content .field-name-body p {
    font-size: 1em;
    letter-spacing: 0.5px;
    line-height: 1.2;
  }
  .path-event .webform-submit:focus {
    visibility: hidden;
  }
}

/* Start: fix bootstrap nav-ber bug on screen 768px http://stackoverflow.com/a/36289507 */
@media (max-width: 991px) {
  .navbar-header {
    float: none;
  }
  .navbar-left, .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }
}

/* End: fix bootstrap nav-ber bug on screen 768px */
@media print {
  body {
    min-width: 1200px;
    background: none;
    zoom: 0.9;
  }
  html {
    background: none;
  }
  html, body, #navigation, #header, #content, #secondary-menu, #page {
    height: auto;
    max-width: 100%;
    overflow: auto;
  }
  #block-hybridauth-hybridauth {
    display: none;
  }
  .container {
    width: auto;
  }
  #header {
    padding: 0 !important;
  }
  #header > .container > .row > div {
    overflow: hidden;
  }
  h1.page-title {
    text-align: center;
    font-size: 50px;
    margin-top: 0;
  }
  .view-header {
    visibility: hidden;
  }
  thead {
    display: table-header-group;
  }
  input[type="button"], .tabs, .breadcrumb, #header-top {
    display: none;
  }
  a[href]:after {
    content: "";
  }
  body.admin-menu.adminimal-menu:before, #boot-press-to-top {
    display: none !important;
  }
  table {
    margin: 0;
  }
  .block, #main {
    padding: 0;
  }
  .qua-check-1:before {
    content: "" !important;
  }
  #logo img.print-only {
    display: block;
  }
  #logo img.header--logo {
    float: left;
    height: 120px;
  }
  #logo img.header--sponsor {
    display: none;
    float: right;
    width: 500px;
    height: auto;
    margin-top: -20px;
    position: relative;
    top: 12px;
  }
}
